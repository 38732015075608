var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kpi-container"},[_c('card-kpi-companies',{attrs:{"stats":_vm.stats,"active":_vm.active,"header":[
      {
        value: 1,
        label: 'Todas as Empresas'
      },
      {
        value: 2,
        label: 'Em Autodiagnóstico'
      },
      {
        value: 3,
        label: 'C/ Facilitador'
      }
    ],"set-active":function (value) {_vm.active = value},"date1":_vm.date1,"set-date1":function (value) {
      _vm.date1 = value
    },"date2":_vm.date2,"set-date2":function (value) {
      _vm.date2 = value
    },"search":_vm.search}}),_c('card-kpi-region-sector',{attrs:{"stats":_vm.stats_region_sector,"header":[
      {
        value: 1,
        label: 'Regiões'
      },
      {
        value: 2,
        label: 'Setores'
      } ],"date1":_vm.date1_region_sector,"set-date1":function (value) {
      _vm.date1_region_sector = value
    },"date2":_vm.date2_region_sector,"set-date2":function (value) {
      _vm.date2_region_sector = value
    },"search":_vm.search}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }