<template>
  <div class="kpi-container">
    <card-kpi-companies
      :stats="stats"
      :active="active"
      :header="[
        {
          value: 1,
          label: 'Todas as Empresas'
        },
        {
          value: 2,
          label: 'Em Autodiagnóstico'
        },
        {
          value: 3,
          label: 'C/ Facilitador'
        }
      ]"
      :set-active="(value) => {active = value}"
      :date1="date1"
      :set-date1="(value) => {
        date1 = value
      }"
      :date2="date2"
      :set-date2="(value) => {
        date2 = value
      }"
      :search="search"
    />

    <card-kpi-region-sector
      :stats="stats_region_sector"
      :header="[
        {
          value: 1,
          label: 'Regiões'
        },
        {
          value: 2,
          label: 'Setores'
        },
      ]"
      :date1="date1_region_sector"
      :set-date1="(value) => {
        date1_region_sector = value
      }"
      :date2="date2_region_sector"
      :set-date2="(value) => {
        date2_region_sector = value
      }"
      :search="search"
    />
  </div>
</template>

<script>
import { get } from '../../../services/api';
import CardKPICompanies from '../CardKPICompanies.vue';
import CardKPIRegionSector from '../CardKPIRegionSector.vue';

export default {
  name: 'DashboardKPIConsortium',
  components: { 'card-kpi-companies': CardKPICompanies, 'card-kpi-region-sector': CardKPIRegionSector },
  data() {
    return {
      active: null,
      quiz_facilitator_stats: null,
      stats: null,
      stats_region_sector: null,
      date1: new Date(
        new Date().getFullYear() - 1,
        new Date().getMonth(),
        new Date().getDate(),
      ).toLocaleDateString(),
      date2: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
      ).toLocaleDateString(),
      date1_region_sector: new Date(
        new Date().getFullYear() - 1,
        new Date().getMonth(),
        new Date().getDate(),
      ).toLocaleDateString(),
      date2_region_sector: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
      ).toLocaleDateString(),
    };
  },
  watch: {
    active: {
      handler() {
        this.search();
      },
    },
  },

  async created() {
    this.active = 1;
    this.loaded = true;
  },
  methods: {
    async search() {
      await get(`quiz_stats?from=
        ${this.date1.split('/').reverse().join('-')}&to=${this.date2.split('/').reverse().join('-')}
      `)
        .then((response) => {
          this.quiz_facilitator_stats = response.data;
          if (this.active === 1) {
            this.stats = {
              big: this.quiz_facilitator_stats.big,
              pme: this.quiz_facilitator_stats.pme,
              micro: this.quiz_facilitator_stats.micro,
              quizzes: this.quiz_facilitator_stats.quizzes,
              submitted: this.quiz_facilitator_stats.submitted,
              media: this.quiz_facilitator_stats.media,
              companies_s2f: this.quiz_facilitator_stats.companies_s2f,
            };
          } else if (this.active === 2) {
            this.stats = {
              big: this.quiz_facilitator_stats.big_wo_facilitator,
              pme: this.quiz_facilitator_stats.pme_wo_facilitator,
              micro: this.quiz_facilitator_stats.micro_wo_facilitator,
              quizzes: this.quiz_facilitator_stats.quizzes_wo_facilitator,
              submitted: this.quiz_facilitator_stats.submitted_wo_facilitator,
              media: this.quiz_facilitator_stats.media_wo_facilitator,
              companies_s2f: this.quiz_facilitator_stats.companies_s2f,
            };
          } else if (this.active === 3) {
            this.stats = {
              big: this.quiz_facilitator_stats.big_w_facilitator,
              pme: this.quiz_facilitator_stats.pme_w_facilitator,
              micro: this.quiz_facilitator_stats.micro_w_facilitator,
              quizzes: this.quiz_facilitator_stats.quizzes_w_facilitator,
              submitted: this.quiz_facilitator_stats.submitted_w_facilitator,
              media: this.quiz_facilitator_stats.media_w_facilitator,
              companies_s2f: this.quiz_facilitator_stats.companies_s2f,
            };
          }
        });
      await get(`quiz_region_sector_stats?from=
        ${this.date1_region_sector.split('/').reverse().join('-')}&to=${this.date2_region_sector.split('/').reverse().join('-')}
      `).then((response) => {
        this.stats_region_sector = response.data;
      });
    },
  },
};
</script>
